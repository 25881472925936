import { createRouter, createWebHistory } from 'vue-router'

import HomeOnePage from "../Pages/HomeOnePage.vue";
import HomeTwoPage from "../Pages/HomeTwoPage.vue";
import HomeThreePage from "../Pages/HomeThreePage.vue";
import AboutPage from "../Pages/AboutPage.vue";
import AboutTwoPage from "../Pages/AboutTwoPage.vue";
import InstructorPage from "../Pages/InstructorPage.vue";
import InstructorTwoPage from "../Pages/InstructorTwoPage.vue";
import InstructorDetailsPage from "../Pages/InstructorDetailsPage.vue";
import EventsPage from "../Pages/EventsPage.vue";
import EventsDetailsPage from "../Pages/EventsDetailsPage.vue";
import CoursesPage from "../Pages/CoursesPage.vue";
import CoursesListPage from "../Pages/CoursesListPage.vue";
import CoursesSidebarPage from "../Pages/CoursesSidebarPage.vue";
import CoursesDetailsPage from "../Pages/CoursesDetailsPage.vue";
import BlogPage from "../Pages/BlogPage.vue";
import BlogSidebarPage from "../Pages/BlogSidebarPage.vue";
import BlogStandardPage from "../Pages/BlogStandardPage.vue";
import BlogDetailsPage from "../Pages/BlogDetailsPage.vue";
import ContactPage from "../Pages/ContactPage.vue";
import ErrorPage from "../Pages/ErrorPage.vue";
import CoursesDetails from '@/components/CoursesDetails/CoursesDetails.vue';
import AppPage from '@/Pages/AppPage.vue';
import DigitalCourse from '@/components/CoursesDetails/DigitalCourse.vue';
import SeoPage from '@/components/CoursesDetails/SeoPage.vue';
import WebDevelopment from '@/components/CoursesDetails/WebDevelopment.vue';

import SeoCourse from '@/components/CoursesDetails/SeoCourse.vue';
import AppCourse from '@/components/CoursesDetails/AppCourse.vue';
import GraphicCourse from '@/components/CoursesDetails/GraphicCourse.vue';
import VideoEditing from '@/components/CoursesDetails/VideoEditing.vue';
import CoreFeatures from '@/components/HomeOne/CoreFeatures.vue';
import CoreFeaturesPage from '@/Pages/CoreFeaturesPage.vue';


const routes = [
  {
    path: "/",
    name: "HomeOnePage",
    component: HomeOnePage,
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
  },
  //
  {
    path: "/instructor",
    name: "InstructorPage",
    component: InstructorPage,
  },
  
  {
    path: "/instructor-details",
    name: "InstructorDetailsPage",
    component: InstructorDetailsPage,
  },
  {
    path: "/event",
    name: "EventsPage",
    component: EventsPage,
  },
  {
    path: "/event-details",
    name: "EventsDetailsPage",
    component: EventsDetailsPage,
  },
  {
    path: "/courses",
    name: "CoursesPage",
    component: CoursesPage,
  },
  {
    path: "/courses-list",
    name: "CoursesListPage",
    component: CoursesListPage,
  },
  {
    path: "/courses-sidebar",
    name: "CoursesSidebarPage",
    component: CoursesSidebarPage,
  },
  {
    path: "/courses-details",
    name: "CoursesDetailsPage",
    component: CoursesDetailsPage,
  },
  {
    path: "/blog",
    name: "BlogPage",
    component: BlogPage,
  },
  {
    path: "/blog-sidebar",
    name: "BlogSidebarPage",
    component: BlogSidebarPage,
  },
  {
    path: "/blog-standard",
    name: "BlogStandardPage",
    component: BlogStandardPage,
  },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: ContactPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage,
  },
  {
    path: "/courses-details/seo",
    name: "SeoCourse",
    component: SeoCourse,
  },
  {
    path: "/courses-details/webdevelopment",
    name: "WebDevelopment",
    component: WebDevelopment,
  },{
    path: "/courses-details/appdevelopment",
    name: "AppCourse",
    component: AppCourse,
  },
  {
    path: "/courses-details/graphicdesign",
    name: "GraphicCourse",
    component: GraphicCourse,
  },
  {
    path: "/courses-details/videoediting",
    name: "VideoEditing",
    component: VideoEditing,
  },{
    path: "/courses-details/digitalmarketing",
    name: "DigitalCourse",
    component: DigitalCourse,
  },
  {
    path: "/CoreFeatures",
    name: "CoreFeatures",
    component: CoreFeaturesPage,
  },
 
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { top: 1, behavior: "smooth" };
  },
})

export default router
