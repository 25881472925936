<template>
  <!-- <div id="popular-topics" class="edu-topics-area  pt-100">

    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Start Learning From Today</p>
        <h2>Popular <span class="shape02">Topics</span> To Learn</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <router-link to="/courses-details/graphicdesign">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/t1.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Graphic Designing</h3>
                <p>68 Courses</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <router-link to="/courses-details/appdevelopment">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/user-interface.png" alt="icon" />
              </div>
              <div class="content">
                <h3>App Development</h3>
                <p>44 Courses</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <router-link to="/courses-details/webdevelopment">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/internet.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Web Development</h3>
                <p>65 Courses</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <router-link to="/courses-details/digitalmarketing">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/social-media.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Digital Marketing</h3>
                <p>12 Courses</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <router-link to="/courses-details/videoediting">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/video-editing-app.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Video Editing</h3>
                <p>33 Courses</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <router-link to="/courses-details/seo">
            <div class="single-topic-box lg-04">
              <div class="icon">
                <img src="../../assets/img/icon/seo.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Seo</h3>
                <p>22 Courses</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div> -->
  <div class="edu-platfrom-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12 col-sm-12">
        <div class="edu-platfrom-content">
          <p class="sub-title">Best Online Learning Platform</p>
          <h2><span class="shape02">Our Mission</span></h2>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered.</p>

          <div class="platfrom-list">
            <div class="items">
              <div class="edu-platfrom-img">
                <img src="../../assets/img/svg/i.svg" alt="banner-img" />
              </div>
              <div class="platfrom-single-content">
                <h3><b style="color: #00BAFE; font-size: 23px ">Inspire</b> innovation in technology-driven environments</h3>
              </div>
            </div>

            <div class="items">
              <div class="edu-platfrom-img">
                <img src="../../assets/img/svg/t.svg" alt="banner-img" />
              </div>
              <div class="platfrom-single-content">
                <h3><b style="color: #00BAFE;  font-size: 23px;">Train</b> leaders through advanced programs</h3>
              </div>
            </div>

            <div class="items">
              <div class="edu-platfrom-img">
                <img src="../../assets/img/svg/e.svg" alt="banner-img" />
              </div>
              <div class="platfrom-single-content">
                <h3><b style="color: #00BAFE; font-size: 23px ">Empower</b> trainees to excel in both public and private sectors</h3>
              </div>
            </div>

            <div class="items">
              <div class="edu-platfrom-img">
                <img src="../../assets/img/svg/c.svg" alt="banner-img" />
              </div>
              <div class="platfrom-single-content">
                <h3><b style="color: #00BAFE; font-size: 23px ">Cultivate</b> creativity and leadership in the tech industry</h3>
              </div>
            </div>

            <div class="items">
              <div class="edu-platfrom-img">
                <img src="../../assets/img/svg/h.svg" alt="banner-img" />
              </div>
              <div class="platfrom-single-content">
                <h3><b style="color: #00BAFE; font-size: 23px ">Harness</b> potential through cutting-edge education</h3>
              </div>
            </div>
          </div>

          <a href="#" class="default-btn">Explore Our Courses</a>
        </div>
      </div>

      <div class="col-lg-7 col-md-12 col-sm-12">
        <div class="edu-platfrom-image">
          <img src="../../assets/img/all-img/final.png" alt="banner-img" />
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: "CoreFeatures",
  methods: {
    scrollToSection(id) {
      this.$nextTick(() => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  },
};
</script>

<h3> </h3>
<p>
</p>