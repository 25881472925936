<template >
    <MainNavbar />
    
    <CoreFeatures />
    
    <MainFooter />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import MainNavbar from "../components/Layouts/MainNavbar.vue";

  import CoreFeatures from "../components/HomeOne/CoreFeatures.vue";

  import MainFooter from "../components/Layouts/MainFooter.vue";
  
  export default defineComponent({
    name: "CoreFeaturesPage",
    components: {
      MainNavbar,
      
      CoreFeatures,
     
      MainFooter,
    },
  });
  </script>