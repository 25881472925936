<template>
  <div :class="['edu-navbar-area navbar-area', { 'is-sticky': isSticky }]">
    <div class="iTechTribe-nav">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/logo/new logo.svg" height="100px" width="100px" alt="logo" />
          </router-link>

          <div
            class="navbar-toggler"
            @click="active = !active"
            :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ active: button_active_state }"
            v-on:click="button_active_state = !button_active_state"
          >
            <i class="bx bx-menu"></i>
            <i class="bx bx-x"></i>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/about" class="nav-link">
                  About 
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="#"
                  class="nav-link"
                  @click.prevent="handleCoursesClick"
                >
                  Courses
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="#"
                  class="nav-link"
                  @click.prevent="scrollToFAQSection"
                >
                  FAQ
                </router-link>
              </li>
            </ul>
          </div>
          <div class="nav-btn">
            <router-link to="/contact" class="default-btn">
              Contact Us
            </router-link>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainNavbar",
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
    };
  },
  methods: {
    handleCoursesClick() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.scrollToSection('popular-topics');
        });
      } else {
        this.scrollToSection('popular-topics');
      }
    },
    scrollToFAQSection() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.scrollToSection('faq');
        });
      } else {
        this.scrollToSection('faq');
      }
    },
    scrollToSection(id) {
      setTimeout(() => {
        const section = document.getElementById(id);
        if (section) {
          console.log(`Scrolling to section with id: ${id}`);
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
          console.error(`Section with id: ${id} not found.`);
        }
      }, 300); // Delay to ensure DOM updates before scroll
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollPos = window.scrollY;
      this.isSticky = scrollPos >= 100;
    });
  },
});
</script>

<style scoped>
/* Add your styles here */
</style>
