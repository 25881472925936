<template>
  <div class="edu-services-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Core Features</p>
        <h2>Why <span class="shape02">Choose</span> iTechTribe</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-layer"></i>
            </div>
            <h3>Learn More Anywhere</h3>
            <p>
              Learn from anywhere in world on desktop, mobile or tablet with an
              Internet connection.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-chalkboard"></i>
            </div>
            <h3>Expert Instructor</h3>
            <p>
              Learn from anywhere in world on desktop, mobile or tablet with an
              Internet connection.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-support"></i>
            </div>
            <h3>24/7 Strong Support</h3>
            <p>
              Learn from anywhere in world on desktop, mobile or tablet with an
              Internet connection.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreFeatures",
};
</script>