<template>
  <div class="preloader-area position-fixed text-center" id="preloader">
    <div class="loader">
      <div class="waviy">
        <img src="../../assets/img/logo/bg.svg" alt="icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>

<style lang="scss">
.preloader-area {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background-color: var(--whiteColor);
  display: flex;
  justify-content: center;
  align-items: center; /* Ensures perfect vertical and horizontal centering */

  .loader {
    position: relative;

    .waviy {
      img {
        position: relative;
        animation: bounce 2s infinite; /* Bounce animation applied */
      }
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
</style>
