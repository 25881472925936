<template>
  <div id="faq" class="edu-faq-area sectionBg15 ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-faq-content">
            <p class="sub-title">Frequently Asked Question</p>
            <h2>General <span class="shape02">Questions</span></h2>
          </div>
          <div class="accordion edu-faqs-list" id="accordionFlushExample">
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingOne">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
               What is Cloud Applied Generative AI Engineering?
              </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Cloud Applied Generative AI Engineering (GenEng) is the application of generative AI technologies to solve real-world problems in the cloud.
                </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingTow">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTow"
                  aria-expanded="false"
                  aria-controls="flush-collapseTow"
                >
                How valuable are the Cloud Applied Generative AI developers?                </button>
              </h2>
              <div
                id="flush-collapseTow"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingTow"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Developers with expertise in Cloud Applied Generative AI were in extremely high demand due to the increasing adoption of GenAI technologies across various industries. However, the supply of developers skilled specifically in this niche area might not have been as abundant compared to more generalized AI or cloud computing roles.

             </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingThree">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                Why do we have to learn two programming languages?                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    You are learning two programming languages in the first quarter of the GenEng certification program because they are both essential for developing smart applications with GenAI.
              </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingFour">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                Is the program not too long, one year is a long time?                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    The length of the program is one year which is broken down into four quarters of three months each. The program covers a wide range of topics including TypeScript, Python, Front-end Development, GenAI, API, Database, Cloud Development, and DevOps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-about-image">
            <img src="../../assets/img/all-img/faq.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialContent",
};
</script>